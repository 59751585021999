import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Box, Themed } from "theme-ui"

const ArxiuDeNoticies = ({ data }) => {

  const dataFragments = data.dataJson

  return (
    <Layout>
      <Box
        sx={{
          ".date": {
            fontSize: 0,
          }
        }}
      >
      <Themed.h1>{dataFragments.fragment_1}</Themed.h1>
      {data.allWpPost.nodes.map((node, i) => (
        <Box key={i}>
          <Themed.h2><Themed.a as={Link} to={`/${node.slug}`} dangerouslySetInnerHTML={{ __html: node.title}} /></Themed.h2>
          <Themed.p className="date">{node.date}</Themed.p>
        </Box>
      ))}
      </Box>
    </Layout>
  )

}

export default ArxiuDeNoticies

export const pageQuery = graphql`
  query ArxiuDeNoticies {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        excerpt
        slug
        date(formatString: "DD-MM-YYYY")
      }
    }
    dataJson(slug: {eq: "page-arxiu-de-noticies"}) {
      fragment_1
    }
  }
`
